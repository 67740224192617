const apiDomains = {
  production: 'https://front.kireireport.com/api/v1',
  staging: 'https://front.staging.kireireport.com/api/v1',
  development: 'https://front.local.kireireport.com:3000/api/v1',
} as {
  [key: string]: string
}

const ISO_8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
// 日付の文字列をDate型に変換する
// 再帰的にオブジェクトを探索する
// NOTE: superjsonとかも良さそう？
const parseAndConvertDateStrings = (obj: any): any => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] instanceof Object) {
      parseAndConvertDateStrings(obj[key])
    } else if (typeof obj[key] === 'string' && ISO_8601.test(obj[key])) {
      obj[key] = new Date(obj[key])
    }
  })
  return obj
}
const buildUrl = (url: string) => {
  const config = useRuntimeConfig()
  return apiDomains[config.public.environment || 'development'] + url
}
const onRequest = () => {}
const onRequestError = () => {}
const onResponse = () => {}
const onResponseError = () => {}
const transform = (value: any) => {
  return parseAndConvertDateStrings(value)
}
const defaultOptions = {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
  transform,
}

// NOTE: urlはドメイン/api/v1/以下のパスを指定する
// ex) /api/v1/users -> /users
// /api/v1はv2になるときにまとめて変更できるように。
// FIXME: anyを無くす。型をちゃんと定義する。
export async function useRemoteServerApi<T>(
  url: string,
  options?: { method: 'GET' | 'POST' | 'DELETE' | 'PATCH'; body?: Object },
  headers?: HeadersInit
): Promise<{ data: T }> {
  const result = await $fetch(buildUrl(url), {
    ...defaultOptions,
    ...options,
    headers,
  })
  return {
    data: result as T,
  }
}
