import DOMPurify from 'isomorphic-dompurify'
import { marked } from 'marked'

marked.setOptions({ breaks: true })
marked.use({ mangle: false })
marked.use({ headerIds: false })
type Option = {
  renderType: 'contentText' | 'description'
}

export default (
  text: string,
  option: Option = {
    renderType: 'contentText',
  }
) => {
  return DOMPurify.sanitize(marked.parse(text), purifyOption(option))
}

const purifyOption = (option: Option) => {
  switch (option.renderType) {
    case 'contentText':
      return { FORBID_TAGS: ['style'] }
    case 'description':
      return {
        ALLOWED_TAGS: [],
        FORBID_TAGS: ['style'],
      }
    default:
      return { FORBID_TAGS: ['style'] }
  }
}
