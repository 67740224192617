export async function useFetchWithAuthUser<T>(
  url: string,
  options?: { method: 'GET' | 'POST' | 'DELETE' | 'PATCH'; body?: Object }
): Promise<{ data: T }> {
  const auth = useAuth()
  const headers = {
    // Front::JwtAuthentication::HEADER_TOKEN_KEYと同値
    'X-Kireireport-Token': auth.getToken || '',
  }
  const { data } = await useRemoteServerApi<T>(url, options, headers)
  return {
    data,
  }
}
