export default defineNuxtPlugin(() => {
  return {
    provide: {
      convertAllValueToValueOf: <T extends Object>(obj: T) => {
        return convert(obj)
      },
    },
  }
})

function convert<T extends Object>(obj: T) {
  const map = new Map()

  // オブジェクトの各プロパティについて処理を行う
  for (const [key, value] of Object.entries(obj)) {
    if (value === null || value === undefined) {
      map.set(key, null)
      // プロパティの値がオブジェクトの場合は再帰的に変換する
    } else if (typeof value === 'object') {
      map.set(key, convert(value))
    } else {
      // プロパティの値が文字列の場合は数値に変換する
      map.set(key, value.valueOf())
    }
  }
  // Mapオブジェクトをオブジェクトに変換して返す
  return Object.fromEntries(map)
}
