import type { PrefectureWithSection } from '~/typeEntities/stores/Prefecture'
import type { Section } from '~/typeEntities/stores/Section'

export const usePrefecturesStore = defineStore('prefectures-store', () => {
  const prefectures = ref([] as PrefectureWithSection[])
  const setPrefectures = (prefecturesArg: PrefectureWithSection[]) => {
    prefectures.value = prefecturesArg
  }
  const fetchPrefectures = async () => {
    if (prefectures.value.length === 0) {
      const { data } = await useFetch('/api/v1/prefectures')
      if (data.value) {
        setPrefectures(data.value.prefectures)
      }
    }
  }
  const getPrefecture = (id: number) => {
    return prefectures.value?.find((pref) => Number(pref.id) === id)
  }
  const getSections = computed(() => {
    return prefectures.value.flatMap((pref) => pref.sections)
  })
  function getSection(id: number): Section | undefined
  function getSection(id: number[]): Section[]
  function getSection(id: number | number[]): Section | undefined | Section[] {
    if (Array.isArray(id)) {
      return getSections.value.filter((section) => id.includes(section.id))
    }
    return getSections.value.find((section) => section.id === id)
  }
  return {
    prefectures,
    setPrefectures,
    fetchPrefectures,
    getPrefecture,
    getSection,
  }
})
