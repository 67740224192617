import type { DetailedCondition } from '~/typeEntities/stores/DetailedCondition'
import type { TreatmentWithChoosableGroup } from '~/typeEntities/stores/Treatment'

export const useTreatmentStore = defineStore('choosable-group-store', {
  state: () => ({
    treatments: [] as TreatmentWithChoosableGroup[],
    selectedOptions: [] as number[],
  }),
  actions: {
    setTreatment(treatments: TreatmentWithChoosableGroup[]) {
      this.treatments = treatments
    },
    setSelectedOptions(selectedOptions: number[]) {
      this.selectedOptions = selectedOptions
    },
    async fetchConditions() {
      const treatmentIds = this.tcStore.getSelectedTreatment.map(
        (treatment) => treatment.id
      )
      if (treatmentIds.length === 0) {
        return
      }
      const { data } = await useFetch('/api/v1/treatments/search', {
        params: { treatmentIds },
      })
      if (data.value) {
        this.setTreatment(data.value.treatments)
      }
    },
    addSelectedCondition(conditionId: number) {
      this.selectedOptions.push(conditionId)
    },
    removeSelectedCondition(conditionId: number) {
      this.selectedOptions = this.selectedOptions.filter(
        (op) => op !== conditionId
      )
    },
    flushSelectedCondition() {
      this.selectedOptions = []
    },
    async fetchConditionsByTreatmentIds(treatmentIds: number[]) {
      if (treatmentIds.length === 0) {
        return
      }
      const { data } = await useFetch('/api/v1/treatments/search', {
        params: { treatmentIds },
      })
      if (data.value) {
        this.setTreatment(data.value.treatments)
      }
    },
  },
  getters: {
    tcStore: () => {
      return useTreatmentCategoryModalStore()
    },
    isConditionSelected: (state) => {
      return (id: number) => {
        return state.selectedOptions.includes(id)
      }
    },
    getParamsObject: (state) => {
      return { detailedConditionIds: state.selectedOptions }
    },
    getDetailedConditions: (state) => {
      let retArray = [] as DetailedCondition[]
      state.treatments.forEach((treatment) => {
        treatment.choosableGroups.forEach((group) => {
          retArray = retArray.concat(
            group.conditionGroupings.map((cg) => {
              return cg.detailedCondition
            })
          )
        })
      })
      return retArray
    },
    getSelectedSnippet(state) {
      return () => {
        if (state.treatments.length === 0) {
          return null
        }
        const selectedNameArray = state.selectedOptions.map((option) => {
          return this.getDetailedConditions.find(
            (condition) => Number(option) === Number(condition.id)
          )?.name
        })

        if (selectedNameArray.length === 0) {
          return null
        }

        return selectedNameArray.join(',')
      }
    },
  },
})
