import { FirebaseError } from 'firebase/app'
import {
  getAuth as getFirebaseAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  verifyBeforeUpdateEmail,
  type UserCredential,
  OAuthProvider,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from 'firebase/auth'
import type { CurrentUser } from '~/typeEntities/stores/CurrentUser'

type TokenResponse = {
  token: string
}

const handleFireabaseError = (error: FirebaseError) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'このメールアドレスは既に使用されています'
    case 'auth/invalid-credential':
      return 'メールアドレスまたはパスワードが間違っています'
    case 'auth/missing-email':
      return 'メールアドレスが入力されていません'
    case 'auth/too-many-requests':
      return 'ログイン試行リクエストが複数回失敗しています。時間をおいて再度お試しください。'
    default:
      return 'エラーが発生しました。時間をおいて再度お試しください。'
  }
}

export const useAuth = defineStore('auth', {
  state: () => ({
    token: null as string | null,
    TOKEN_STORAGE_KEY: 'X-Kireireport-Token',
    PROVIDER: {
      APPLE: 'apple.com',
      EMAIL_WITH_PASSWORD: 'password',
    },
    currentUser: null as CurrentUser | null,
  }),
  actions: {
    async signupWithEmailAndPassword(email: string, password: string) {
      const userCredential = await createUserWithEmailAndPassword(
        getFirebaseAuth(),
        email,
        password
      ).catch((error: unknown) => {
        if (error instanceof FirebaseError) {
          return handleFireabaseError(error)
        } else {
          throw error
        }
      })
      if (!userCredential || typeof userCredential === 'string')
        return {
          isSuccess: false,
          errorMessage: userCredential || '',
        }
      await this._authenticate(userCredential)
      return {
        isSuccess: true,
        errorMessage: null,
      }
    },
    async signInWithEmailAndPassword(email: string, password: string) {
      const userCredential = await signInWithEmailAndPassword(
        getFirebaseAuth(),
        email,
        password
      ).catch((error: unknown) => {
        if (error instanceof FirebaseError) {
          return handleFireabaseError(error)
        } else {
          throw error
        }
      })
      if (!userCredential || typeof userCredential === 'string') {
        return {
          isSuccess: false,
          errorMessage: userCredential || '',
        }
      }
      await this._authenticate(userCredential)
      return {
        isSuccess: true,
        errorMessage: null,
      }
    },
    async reauthenticateWithPassword(password: string) {
      const authedCurrentUser = getFirebaseAuth().currentUser
      if (!authedCurrentUser || !authedCurrentUser.email)
        return {
          isSuccess: false,
          errorMessage: 'ユーザが見つかりませんでした',
        }
      const emailAuthCredential = EmailAuthProvider.credential(
        authedCurrentUser.email,
        password
      )
      const userCredential = await reauthenticateWithCredential(
        authedCurrentUser,
        emailAuthCredential
      ).catch((error: unknown) => {
        if (error instanceof FirebaseError) {
          return handleFireabaseError(error)
        } else {
          throw error
        }
      })
      if (!userCredential || typeof userCredential === 'string') {
        return {
          isSuccess: false,
          errorMessage: userCredential || '',
        }
      }
      await this._authenticate(userCredential)
      return {
        isSuccess: true,
        errorMessage: null,
      }
    },
    async verifyBeforeUpdateEmail(email: string) {
      const firebaseCurrentUser = getFirebaseAuth().currentUser
      if (!firebaseCurrentUser) {
        return {
          isSuccess: false,
          errorMessage: 'ユーザが見つかりませんでした',
        }
      }
      const host = (() => {
        const config = useRuntimeConfig()
        if (config.public.environment === 'production') {
          return 'https://kireireport.com'
        } else if (config.public.environment === 'staging') {
          return 'https://staging.kireireport.com'
        } else {
          return 'http://local.kireireport.com:3333'
        }
      })()
      const actionCodeSettings = {
        url: `${host}/mypage/account-setting/email/success`,
        handleCodeInApp: false,
      }
      const errorMessage = await verifyBeforeUpdateEmail(
        firebaseCurrentUser,
        email,
        actionCodeSettings
      ).catch((error: unknown) => {
        if (error instanceof FirebaseError) {
          return handleFireabaseError(error)
        } else {
          throw error
        }
      })
      if (!errorMessage) {
        return {
          isSuccess: true,
          errorMessage: null,
        }
      } else {
        return {
          isSuccess: false,
          errorMessage,
        }
      }
    },
    async updatePassword(password: string) {
      const firebaseCurrentUser = getFirebaseAuth().currentUser
      if (!firebaseCurrentUser) {
        return {
          isSuccess: false,
          errorMessage: 'ユーザが見つかりませんでした',
        }
      }
      const errorMessage = await updatePassword(
        firebaseCurrentUser,
        password
      ).catch((error: unknown) => {
        if (error instanceof FirebaseError) {
          return handleFireabaseError(error)
        } else {
          throw error
        }
      })
      if (!errorMessage) {
        return {
          isSuccess: true,
          errorMessage: null,
        }
      } else {
        return {
          isSuccess: false,
          errorMessage,
        }
      }
    },
    async recoverPassword(email: string) {
      const host = (() => {
        const config = useRuntimeConfig()
        if (config.public.environment === 'production') {
          return 'https://kireireport.com'
        } else if (config.public.environment === 'staging') {
          return 'https://staging.kireireport.com'
        } else {
          return 'http://local.kireireport.com:3333'
        }
      })()
      const actionCodeSettings = {
        url: `${host}/session/login`,
        handleCodeInApp: false,
      }
      const error = await sendPasswordResetEmail(
        getFirebaseAuth(),
        email,
        actionCodeSettings
      ).catch((error: unknown) => {
        if (error instanceof FirebaseError) {
          return handleFireabaseError(error)
        } else {
          throw error
        }
      })
      if (error) {
        return {
          isSuccess: false,
          errorMessage: error,
        }
      } else {
        return {
          isSuccess: true,
          errorMessage: null,
        }
      }
    },
    trySetToken() {
      const token = useStorage.getItem(this.TOKEN_STORAGE_KEY)
      if (!token) {
        return
      }
      this._setToken(token)
    },
    async logout() {
      await signOut(getFirebaseAuth())
      this.currentUser = null
      this._clearToken()
      reloadNuxtApp()
    },
    async fetchCurrentUser() {
      if (!this.isLoggedIn) return null
      if (this.currentUser) return this.currentUser
      const { data } = await useFetchWithAuthUser<{ currentUser: CurrentUser }>(
        '/current_user/profile',
        {
          method: 'GET',
        }
      )
      this.currentUser = data.currentUser
      return this.currentUser
    },
    async updateCurrentUser(params: Partial<CurrentUser>) {
      if (!this.isLoggedIn) return false

      const { data } = await useFetchWithAuthUser<{ currentUser: CurrentUser }>(
        '/current_user/profile',
        {
          method: 'PATCH',
          body: { currentUser: params },
        }
      ).catch(() => {
        return { data: null }
      })
      if (data) this.currentUser = data.currentUser
      return !!data
    },
    async updateCurrentUserThumbnail(file: File) {
      if (!this.isLoggedIn) return false
      const formData = new FormData()
      formData.append('currentUser[thumbnail]', file)
      const { data } = await useFetchWithAuthUser<{ currentUser: CurrentUser }>(
        '/current_user/profile',
        {
          method: 'PATCH',
          body: formData,
        }
      ).catch(() => {
        return { data: null }
      })
      if (data) this.currentUser = data.currentUser
      return !!data
    },
    // private ------------------------------------------------
    async _authenticate(userCredential: UserCredential) {
      const params = await this._createUserParams(userCredential)
      const { data } = await useFetchWithAuthUser<TokenResponse>(
        '/auth/with_firebase',
        {
          method: 'POST',
          body: params,
        }
      )
      if (!data) {
        // この辺のAPIのエラーハンドリングはTODOとしておく
        // TODO: エラーハンドリングを共通化して、ちゃんと挙動も実装する
        return alert('認証に失敗しました')
      }
      this._setToken(data.token)
    },
    async _createUserParams(userCredential: UserCredential) {
      const providerParams = (() => {
        switch (userCredential.providerId) {
          case this.PROVIDER.APPLE: {
            const credential =
              OAuthProvider.credentialFromResult(userCredential)
            return {
              idToken: credential?.idToken,
              accessToken: credential?.accessToken,
            }
          }
          case this.PROVIDER.EMAIL_WITH_PASSWORD:
            return {}
        }
      })()
      return {
        idToken: await userCredential.user.getIdToken(),
        auth: {
          displayName: userCredential.user.displayName,
          refreshToken: userCredential.user.refreshToken,
          tenantId: userCredential.user.tenantId,
          provider: providerParams,
          email: userCredential.user.email,
        },
        currentUrl: window.location.href,
        referrerUrl: document.referrer,
      }
    },
    _setToken(token: string) {
      useStorage.setItem(this.TOKEN_STORAGE_KEY, token)
      this.token = token
    },
    _clearToken() {
      useStorage.removeItem(this.TOKEN_STORAGE_KEY)
      this.token = null
    },
    // ------------------------------------------------ private
  },
  getters: {
    isLoggedIn: (state) => {
      return state.token !== null
    },
    getToken: (state) => {
      return state.token
    },
  },
})
