export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,viewport-fit=cover"},{"name":"description","content":"キレイレポは美容医療クリニックの口コミサイトです。美容皮膚科・美容外科・審美歯科など各種クリニックをご紹介しています。"},{"name":"google-site-verification","content":"en5lHygS4ncgNcKdsQMvlXY78JeGe8-neJRr2tcZF5U"},{"property":"og:title","content":"キレイレポ | 美容医療クリニックの口コミサイト"},{"property":"og:description","content":"キレイレポは美容医療クリニックの口コミサイトです。美容皮膚科・美容外科・審美歯科など各種クリニックをご紹介しています。"},{"property":"og:type","content":"website"},{"property":"og:site_name","content":"キレイレポ"},{"property":"og:image","content":"/meta/ogp.png"},{"property":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"16x16"},{"rel":"icon","href":"/icon.svg","type":"image/svg+xml"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"manifest","href":"/manifest.webmanifest"}],"style":[],"script":[],"noscript":[],"title":"キレイレポ | 美容医療クリニックの口コミサイト"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"