import type { TreatmentCategoryWithTreatment } from '~/typeEntities/stores/TreatmentCategory'
import type { Treatment } from '~/typeEntities/stores/Treatment'

export const useTreatmentCategoryStore = defineStore('treatments-store', () => {
  const categories = ref([] as TreatmentCategoryWithTreatment[])
  const setCategories = (categoriesArg: TreatmentCategoryWithTreatment[]) => {
    categories.value = categoriesArg
  }
  const fetchTreatmentCategories = async () => {
    if (categories.value.length === 0) {
      const { data } = await useFetch('/api/v1/treatment_categories')
      if (data.value) {
        setCategories(data.value.treatmentCategories)
      }
    }
  }
  const getCategory = (id: number) => {
    return categories.value?.find((cat) => Number(cat.id) === id)
  }
  const getCategoryLabel = (id: number) => {
    const cat = getCategory(id)
    return cat?.shortenName || cat?.name || ''
  }
  const getTreatments = computed(() => {
    return categories.value.flatMap((cat) => cat.treatments)
  })

  function getTreatment(id: number): Treatment | undefined
  function getTreatment(id: number[]): Treatment[]
  function getTreatment(
    id: number | number[]
  ): Treatment | undefined | Treatment[] {
    if (Array.isArray(id)) {
      return getTreatments.value.filter((treatment) =>
        id.includes(treatment.id)
      )
    }
    return getTreatments.value.find((treatment) => treatment.id === id)
  }

  function getCategoryByTreatmentId(
    treatmentId: number
  ): TreatmentCategoryWithTreatment | undefined
  function getCategoryByTreatmentId(
    treatmentId: number[]
  ): TreatmentCategoryWithTreatment[]
  function getCategoryByTreatmentId(
    treatmentId: number | number[]
  ):
    | TreatmentCategoryWithTreatment
    | undefined
    | TreatmentCategoryWithTreatment[] {
    if (Array.isArray(treatmentId)) {
      return categories.value
        .filter((cat) => cat.treatments.some((t) => treatmentId.includes(t.id)))
        .map((cat) => ({
          ...cat,
          treatments: cat.treatments.filter((t) => treatmentId.includes(t.id)),
        }))
    }
    const retCat = categories.value.find((cat) =>
      cat.treatments.some((t) => t.id === treatmentId)
    )
    if (!retCat) return undefined
    return {
      ...retCat,
      treatments: retCat.treatments.filter((t) => t.id === treatmentId),
    }
  }

  return {
    categories,
    setCategories,
    fetchTreatmentCategories,
    getCategory,
    getCategoryLabel,
    getTreatment,
    getCategoryByTreatmentId,
  }
})
