import type { RouteLocationNormalized } from 'vue-router'
export default (paramName: string) => {
  return (route: RouteLocationNormalized) => {
    const slug = route.params[paramName]
    if (Array.isArray(slug)) {
      return false
    }
    return /^[a-z0-9]+$/.test(slug)
  }
}
