export default defineNuxtRouteMiddleware(async () => {
  const prefectureStore = usePrefecturesStore()
  const treatmentCategoryStore = useTreatmentCategoryStore()
  const treatmentStore = useTreatmentStore()
  await Promise.all([
    prefectureStore.fetchPrefectures(),
    treatmentCategoryStore.fetchTreatmentCategories(),
  ])
  await treatmentStore.fetchConditions()
})
