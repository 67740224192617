export const useAccessHistoryStore = defineStore(
  'access-history-store',
  () => {
    const lastAccessedAt = ref(null as Date | null)

    const setLastAccessedAt = (date: Date) => {
      lastAccessedAt.value = date
    }
    const isAccessedAfter = (
      date = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000)
    ) => {
      return lastAccessedAt.value ? lastAccessedAt.value > date : false
    }
    return {
      lastAccessedAt,
      setLastAccessedAt,
      isAccessedAfter,
    }
  },
  {
    persist: true,
  }
)
