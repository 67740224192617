// 基本的にpiniaのpersistを使うこと
export const useStorage = {
  setItem(key: string, value: string) {
    localStorage.setItem(key, value)
  },
  getItem(key: string) {
    return localStorage.getItem(key)
  },
  removeItem(key: string) {
    return localStorage.removeItem(key)
  },
}
