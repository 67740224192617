export default defineNuxtPlugin(() => {
  return {
    provide: {
      getCurrentHost: () => {
        if (process.server) {
          const config = useRuntimeConfig()
          if (config.public.environment === 'production') {
            return 'https://kireireport.com'
          } else if (config.public.environment === 'staging') {
            return 'https://staging.kireireport.com'
          } else {
            return 'https://local.kireireport.com:3333'
          }
        } else {
          return `${window.location.protocol}//${window.location.host}`
        }
      },
    },
  }
})
