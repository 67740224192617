export default defineNuxtPlugin(() => {
  return {
    provide: {
      stripPref: (name: string) => {
        if (name.endsWith('都') || name.endsWith('府') || name.endsWith('県')) {
          return name.slice(0, -1)
        } else {
          return name
        }
      },
    },
  }
})
