export default defineNuxtPlugin(() => {
  return {
    provide: {
      render404: () => {
        return createError({
          statusCode: 404,
          message: 'お探しのページは見つかりませんでした。',
          fatal: true,
        })
      },
    },
  }
})
