import type { TreatmentCategoryWithTreatment } from '~/typeEntities/stores/TreatmentCategory'
import type { Treatment } from '~/typeEntities/stores/Treatment'
type SelectedOptions = {
  [key: number]: {
    treatmentIds: number[]
  }
}
export const useTreatmentCategoryModalStore = defineStore(
  'treatments-modal-store',
  {
    state: () => ({
      currentCategoryTabId: 1,
      selectedOptions: {} as SelectedOptions,
    }),
    actions: {
      setSelectedOptions(options: SelectedOptions) {
        this.selectedOptions = options
      },
      setSelectedOptionsByFlatParams(treatmentIds: number[]) {
        const retOption = {} as SelectedOptions
        this.categories
          .filter((c) => c.treatments.some((t) => treatmentIds.includes(t.id)))
          .forEach((c) => {
            retOption[c.id] = {
              treatmentIds: c.treatments
                .filter((t) => treatmentIds.includes(t.id))
                .map((t) => t.id),
            }
          })
        this.selectedOptions = retOption
      },
      setCurrentCategoryTabId(id: number) {
        this.currentCategoryTabId = id
      },
      addSelectedTreatment(treatmentId: number) {
        const tCategory = this.getCurrentCategory
        if (
          !tCategory?.treatments.some(
            (treatment) => Number(treatment.id) === treatmentId
          )
        ) {
          return
        }
        const tCatId = Number(tCategory.id)
        if (!this.selectedOptions[tCatId]) {
          this.selectedOptions[tCatId] = {
            treatmentIds: [treatmentId],
          }
        } else {
          this.selectedOptions[tCatId].treatmentIds.push(treatmentId)
        }
      },
      removeSelectedTreatment(treatmentId: number) {
        const tCategory = this.getCurrentCategory
        if (
          !tCategory?.treatments.some(
            (treatment) => Number(treatment.id) === treatmentId
          )
        ) {
          return
        }
        const tCatId = Number(tCategory.id)
        if (!this.selectedOptions[tCatId]) {
          return
        }
        const treatmentIds = this.selectedOptions[tCatId].treatmentIds
        this.selectedOptions[tCatId].treatmentIds = treatmentIds.filter(
          (id) => id !== treatmentId
        )
      },
      addAllTreatmentInCurrentCategory() {
        const tCategory = this.getCurrentCategory
        const tCatId = Number(tCategory?.id)
        const treatmentIds = tCategory?.treatments.map((t) => t.id) || []
        if (!this.selectedOptions[tCatId]) {
          this.selectedOptions[tCatId] = {
            treatmentIds,
          }
        } else {
          this.selectedOptions[tCatId].treatmentIds.push(...treatmentIds)
        }
      },
      flushSelectedTreatment() {
        this.selectedOptions = {} as SelectedOptions
      },
    },
    getters: {
      categories() {
        return useTreatmentCategoryStore().categories
      },
      isSelectedById: (state) => {
        return (id: number) => {
          return id === state.currentCategoryTabId
        }
      },
      getCategory() {
        return (id: number) => {
          return this.categories.find((cat) => Number(cat.id) === id)
        }
      },
      getCurrentCategory(state): TreatmentCategoryWithTreatment | undefined {
        return this.categories.find(
          (tCat) => Number(tCat.id) === state.currentCategoryTabId
        )
      },
      getCategoryOption: (state) => {
        return (id: number) => {
          return (
            state.selectedOptions[id] || {
              treatmentIds: [],
            }
          )
        }
      },
      getTreatmentInCurrentCategory() {
        return (id: number) => {
          return this.getCurrentCategory?.treatments.find(
            (treatment) => Number(treatment.id) === id
          )
        }
      },
      getCategoryLabel() {
        return (id: number) => {
          const cat = this.getCategory(id)
          return cat?.shortenName || cat?.name || ''
        }
      },
      getTreatmentLabelInCurrentCategory() {
        return (id: number) => {
          const treatment = this.getTreatmentInCurrentCategory(id)
          return treatment?.shortenName || treatment?.name || ''
        }
      },
      getHasActiveTreatment() {
        return (id: number) => {
          const option = this.getCategoryOption(id)
          return option.treatmentIds.length > 0
        }
      },
      getCurrentCategoryOption: (state) => {
        return (
          state.selectedOptions[state.currentCategoryTabId] || {
            treatmentIds: [],
          }
        )
      },
      getIsTreatmentSelectedInCurrentCategory() {
        return (id: number) => {
          return this.getCurrentCategoryOption.treatmentIds.includes(id)
        }
      },
      getParamsObject: (state) => {
        const returnOption = {
          treatmentIds: [] as number[],
        }
        Object.keys(state.selectedOptions).forEach((key) => {
          const id = parseInt(key)
          if (!state.selectedOptions[id]) {
            return
          }

          returnOption.treatmentIds = returnOption.treatmentIds.concat(
            state.selectedOptions[id].treatmentIds
          )
        })
        return returnOption
      },
      getSelectedTreatment(state) {
        let retArray = [] as Treatment[]
        Object.keys(state.selectedOptions).forEach((key) => {
          const id = parseInt(key)
          if (!state.selectedOptions[id]) {
            return
          }
          retArray = retArray.concat(
            state.selectedOptions[id].treatmentIds
              .map((treatmentId) => {
                return this.getCategory(id)?.treatments.find((treatment) => {
                  return Number(treatment.id) === treatmentId
                })
              })
              .filter((el): el is Treatment => el !== undefined)
          )
        })
        return retArray
      },
      getSelectedIds() {
        return () => {
          return this.getSelectedTreatment.map((t) => t.id)
        }
      },
      getSelectedIdsInCategory() {
        return (id: number) => {
          return this.getCategoryOption(id).treatmentIds
        }
      },
      getSelectedSnippet() {
        return () => {
          const nameArray = this.getSelectedTreatment.map(
            (treatment) => treatment.name
          )
          if (nameArray.length > 0) {
            return nameArray.join(',')
          } else {
            return null
          }
        }
      },
    },
  }
)
