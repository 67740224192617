import { setLocale as yupSetLocale, type LocaleObject } from 'yup' // @vee-validate/i18nのsetLocaleと被るので、別名指定をしています。
import { suggestive } from 'yup-locale-ja'

export default defineNuxtPlugin(() => {
  const customLocaleObject: LocaleObject = {
    mixed: {
      required: ({ label }) => (label ? label + 'を' : '') + '入力してください',
    },
  }
  const localeObject = { ...suggestive, ...customLocaleObject }
  // yupのバリデーションメッセージを日本語にする設定
  yupSetLocale(localeObject)
})
