import { defineNuxtPlugin } from '#app'
import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const { $router } = useNuxtApp()
  nuxtApp.vueApp.use(
    createGtm({
      id: 'GTM-KLZ4LFSG',
      vueRouter: $router,
    })
  )
})
