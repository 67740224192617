import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/usr/src/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/src/app/.nuxt/sentry-client-config.mjs";
import plugin_iGLjBbor9q from "/usr/src/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/usr/src/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import gtm_client_OzmBuHLRIb from "/usr/src/app/plugins/gtm.client.ts";
import auth_client_4lHQFpoKNF from "/usr/src/app/plugins/auth.client.ts";
import convertAllValueToValueOf_i6D7sAxTqF from "/usr/src/app/plugins/convertAllValueToValueOf.ts";
import firebase_client_zXNDa0wxFH from "/usr/src/app/plugins/firebase.client.ts";
import getCurrentHost_oLkP7hQKLV from "/usr/src/app/plugins/getCurrentHost.ts";
import isStringPresent_aAGwWVtlDW from "/usr/src/app/plugins/isStringPresent.ts";
import regionEmoji_Xx7nQFP4NF from "/usr/src/app/plugins/regionEmoji.ts";
import render404_ufxv2gvlFx from "/usr/src/app/plugins/render404.ts";
import router_client_W01CLn04zL from "/usr/src/app/plugins/router.client.ts";
import stripPref_c60qkltAGx from "/usr/src/app/plugins/stripPref.ts";
import validate_client_kgij10dQdG from "/usr/src/app/plugins/validate.client.ts";
import vuetify_7h9QAQEssH from "/usr/src/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_iGLjBbor9q,
  plugin_AUP22rrBAc,
  gtm_client_OzmBuHLRIb,
  auth_client_4lHQFpoKNF,
  convertAllValueToValueOf_i6D7sAxTqF,
  firebase_client_zXNDa0wxFH,
  getCurrentHost_oLkP7hQKLV,
  isStringPresent_aAGwWVtlDW,
  regionEmoji_Xx7nQFP4NF,
  render404_ufxv2gvlFx,
  router_client_W01CLn04zL,
  stripPref_c60qkltAGx,
  validate_client_kgij10dQdG,
  vuetify_7h9QAQEssH
]