export default defineNuxtRouteMiddleware((to, _) => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    return navigateTo(
      {
        path: to.path.slice(0, -1),
        query: to.query,
      },
      {
        redirectCode: 301,
      }
    )
  }
})
