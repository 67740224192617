import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { firebase } = config.public

  // Initialize Firebase
  const app = initializeApp(firebase.config)

  // Initialize Firebase Authentication
  getAuth(app)
  // Initialize Analytics and get a reference to the service
  getAnalytics(app)
})
