export default defineNuxtPlugin(() => {
  const router = useRouter()
  const previousUrl = ref<string>('/')

  router.beforeEach((_, from, next) => {
    previousUrl.value = from.fullPath
    next()
  })
  return {
    provide: {
      previousUrl,
    },
  }
})
